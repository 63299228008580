export const carriers = {
  USPS: 'USPS',
  USPSRETURNS: 'USPSReturns',
  FEDEX: 'FedEx',
  INPOST: 'inpost',
  ASDA: 'asda',
  EVRI: 'evri',
  DHLSEKO: 'DHL-SEKO',
  CANADA_POST: 'CanadaPost',
  ROYAL_MAIL: 'RoyalMail',
  NEWGISTICS: 'newgistics',
  COLISSIMO_RN: 'colissimo-rn',
  COLISSIMO_RK: 'colissimo-rk',
  DPD_UK: 'dpd-uk',
  ROYAL_MAIL_REBOUND: 'royal-mail',
  DHL_CONNECT_DE: 'dhl-connect-de',
  DHL_RETOURE_DE: 'dhl-retoure-de',
  UPS_MI: 'ups-mi',
  POSTNORD_DK: 'postnord-dk',
  HERMES: 'hermes',
  POSTE_ITALIANE: 'poste-italiane',
};
